<template>
  <loading v-if="isLoading"></loading>
  <div
    v-else
    id="Vitrine"
    class="d-flex flex-column justify-content-between align-items-center"
    v-touch:swipe="onTouch"
  >
    <div v-if="false" class="mb-0 alert alert-danger">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, aliquid
      dolorum. Molestiae iste inventore repellendus vel autem laborum.
      Consequatur mollitia dolorem omnis est voluptatum possimus.
    </div>
    <router-view class="mt-4 flex-grow-1" v-slot="{ Component }">
      <transition mode="out-in" name="slide">
        <component :is="Component" />
      </transition>
    </router-view>
    <vitrine-nav class="flex-shrink-1"></vitrine-nav>
    <vitrine-nav-mobile class="flex-shrink-1"></vitrine-nav-mobile>
  </div>
</template>

<script>
import VitrineNav from "@/components/VitrineNav/VitrineNav.vue";
import VitrineNavMobile from "@/components/VitrineNavMobile/VitrineNavMobile.vue";
import Loading from "@/components/Loading/Loading.vue";
export default {
  components: {
    VitrineNav,
    VitrineNavMobile,
    Loading,
  },
  data() {
    return {
      routes: {
        next: 0,
        names: ["home", "horaire", "prix", "NoFood", "map", "media"],
      },
      readyToSwipe: true,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    onTouch(e) {
      switch (e) {
        case "left":
          this.routes.next += 1;
          if (this.routes.next > this.routes.names.length) {
            this.routes.next = 0;
          }
          this.$router.push({ name: this.routes.names[this.routes.next] });
          break;
        case "right":
          this.routes.next -= 1;
          if (this.routes.next < 0) {
            this.routes.next = this.routes.names.length - 1;
          }
          this.$router.push({ name: this.routes.names[this.routes.next] });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped src="./Vitrine.css"></style>
