<template>
  <div
    class="p-1 d-flex flex-column m-auto justify-content-center align-content-center"
  >
    <div class="ctt d-flex flex-column flex-sm-row">
      <div class="m-3 text-center">
        <div class="d-flex flex-column pt-5" :class="sttColor.stt">
          <i class="far fa-clock ctt-sign"></i>
          <p class="ctt-status-text ">{{ status[sttColor.curS] }}</p>
        </div>
        <strong class="ctt-text"> {{ StatusText[sttColor.cur] }} </strong>
      </div>
      <div class="m-3 text-center">
        <span class="calendar-date">{{ getDate }}</span><sub style="color:white"> {{ dateFormat[lang]}} </sub>
        <div class="w-100"></div>
        <v-calendar class="col" :rows="2" :columns="$screens({ default: 1, lg: 3 })" :attributes='attributes.attrs'/>
        <div v-if="lang === 'ar'" class="row calendar-info-text mx-auto" dir="rtl">
          <div class="mr-4 mt-2 square-closed"></div><div class="mr-2 mt-2 circle-closed"></div><span class="mr-2">{{ desc[lang][0] }}</span>
          <div class="mr-4 mt-2 square-opened"></div><div class="mr-2 mt-2 circle-opened"></div><span class="mr-2">{{ desc[lang][1] }}</span>
          <div class="mr-2 ml-2 mt-2 circle-current"></div><span class="ml-2">{{ cur[lang] }}</span>
          <span class="mr-2"> {{ info[lang] }}</span>
        </div>
        <div v-else class="row calendar-info-text mx-auto">
          <div class="ml-4 mt-2 square-closed"></div><div class="ml-2 mt-2 circle-closed"></div><span class="ml-2">{{ desc[lang][0] }}</span>
          <div class="ml-4 mt-2 square-opened"></div><div class="ml-2 mt-2 circle-opened"></div><span class="ml-2">{{ desc[lang][1] }}</span>
          <div class="ml-3 mt-2 circle-current"></div><span class="ml-2">{{ cur[lang] }}</span>
          <span class="ml-2">{{ info[lang] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desc: {
        fr: ['Fermé', 'ouvert'],
        en: ['Closed', 'Open'],
        ar: ['مغلق', 'مفتوح'],
      },
      cur: {
        fr: 'Aujourd\'hui',
        en: 'Today',
        ar: 'اليوم الحالي',
      },
      info: {
        fr: '*Le jour de fermeture peut varier en fonction de la météo.',
        en: '*The parc closing day may vary depending on the weather.',
        ar: '*قد يختلف يوم إغلاق الحديقة حسب الطقس.',
      },
      dateFormat: {
        fr: "(JJ-MM-AAAA)",
        en: "(DD-MM-YYYY)",
        ar: "(سنة-شهر-يوم)"
      },
    }
  },
  computed: {
    status() {
      return this.$store.getters['welcome/getStatus'];
    },
    StatusText() {
      return this.$store.getters['welcome/getStatusText'];
    },
    opDate() {
      return this.$store.state.welcome.opDate;
    },
    clDate() {
      return this.$store.state.welcome.clDate;
    },
    getDate() {
      const d = new Date();
      const date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();

      return date;
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
    time() {
      const date = new Date();
      const hour = date.getHours();
      const min = date.getMinutes();
      const Time = hour + ':' + min;

      return Time;
    },
    closedDates() {
      const opnDate = new Date(this.opDate[0], this.opDate[1], this.opDate[2]);
      const clnDate = new Date(this.clDate[0], this.clDate[1], this.clDate[2]);
      const year = opnDate.getFullYear();
      const start = opnDate.setDate(opnDate.getDate() - 1);
      const end = clnDate.setDate(clnDate.getDate() + 1);
      const yend = 2100 + '-' + '12' + '-' + '31';

      return ({
        year,
        yend,
        start,
        end,
      })
    },
    sttColor() {
      var stt;
      var cur;
      var curS;
      const actl = new Date();
      const opnDate = new Date(this.opDate[0], this.opDate[1], this.opDate[2]);
      const clnDate = new Date(this.clDate[0], this.clDate[1], this.clDate[2]);
      const t = this.time.split(':');

      if (actl < opnDate && actl.getDate() >= opnDate.getDate() - 7 && actl.getMonth() === opnDate.getMonth()) {
        stt = 'ctt-status-closed';
        cur = 2;
        curS = 1;
      }
      else if ((actl < opnDate && actl.getDate() < opnDate.getDate() - 7 && actl.getMonth() <= opnDate.getMonth()) || (actl >= clnDate && actl.getMonth() >= clnDate.getMonth())) {
        if (actl.getDate() === clnDate.getDate() && actl.getMonth() === clnDate.getMonth() && t[0] <= 17 && t[1] < 30) {
          stt = 'ctt-status-open';
          cur = 0;
          curS = 0;
        } 
        else {
          stt = 'ctt-status-closed';
          cur = 1;
          curS = 1;
        }
      }
      else {
        stt = 'ctt-status-open';
        cur = 0;
        curS = 0;
      }

      return ({stt, cur, curS});
    },
    attributes() {
      return {
        attrs: [
        {
					highlight: {
						start: { fillMode: 'solid', color: 'red' },
						base: { fillMode: 'light', color: 'red' },
						end: { fillMode: 'solid', color: 'red' },
					},
					dates: { start: new Date(this.closedDates.year - 1, 0, 1), end: this.closedDates.start, },
				},
        {
          highlight: {
						start: { fillMode: 'solid', color: 'green' },
						base: { fillMode: 'light', color: 'green' },
						end: { fillMode: 'solid', color: 'green' },
					},
					dates: { start: new Date(this.opDate[0], this.opDate[1], this.opDate[2]), end:  new Date(this.clDate[0], this.clDate[1], this.clDate[2]), },
				},
				{
					highlight: {
						start: { fillMode: 'solid', color: 'red' },
						base: { fillMode: 'light', color: 'red' },
						end: { fillMode: 'solid', color: 'red' },
					},
					dates: { start: this.closedDates.end, end: this.closedDates.yend, },
				},
        {
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2024, 2, 30),
        },
        {
          highlight: {
            color: 'red',
            fillMode: 'solid',
          },
          dates: new Date(2024, 5, 16),
        },
        {
          highlight: {
          fillMode: 'light',
          color: 'blue',
          },
          dates: new Date(),
        },
			]
		};
    }
  },
};
</script>

<style src="./VitrineHoraire.css" scoped></style>
