<template>
  <div
    class="vitrine-nav-mobile p-3 mb-4 d-md-none align-items-center animate__fadeInUp animate__slower animate__animated"
  >
    <!-- Init  -->
    <router-link :to="{ name: 'home' }">
      <i
        class="vitrine-nav-mobile-item"
        :class="{ 'vitrine-nav-mobile-item-active': $route.name === 'home' }"
      ></i>
    </router-link>
    <!-- Horaire -->
    <router-link :to="{ name: 'horaire' }">
      <i
        class="vitrine-nav-mobile-item"
        :class="{ 'vitrine-nav-mobile-item-active': $route.name === 'horaire' }"
      ></i>
    </router-link>
    <!-- prix -->
    <router-link :to="{ name: 'prix' }">
      <i
        class="vitrine-nav-mobile-item"
        :class="{ 'vitrine-nav-mobile-item-active': $route.name === 'prix' }"
      ></i>
    </router-link>
    <!-- NoFood -->
    <router-link :to="{ name: 'NoFood' }">
      <i
        class="vitrine-nav-mobile-item"
        :class="{ 'vitrine-nav-mobile-item-active': $route.name === 'NoFood' }"
      ></i>
    </router-link>
    <!-- map -->
    <router-link :to="{ name: 'map' }">
      <i
        class="vitrine-nav-mobile-item"
        :class="{ 'vitrine-nav-mobile-item-active': $route.name === 'map' }"
      ></i>
    </router-link>
        <!-- Media  -->
    <router-link :to="{ name: 'media' }">
      <i
        class="vitrine-nav-mobile-item"
        :class="{ 'vitrine-nav-mobile-item-active': $route.name === 'media' }"
      ></i>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped src="./VitrineNavMobile.css"></style>
