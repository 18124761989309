<template>
  <div
    class="header py-4 navbar justify-content-between align-content-center animate__animated animate__slow  animate__fadeInDown"
  >
    <!-- For pc -->
    <router-link to="/">
      <img
        class="navbrand order-1 order-sm-1"
        src="@/assets/logo-ar.webp"
        alt=""
      />
    </router-link>
    <the-navbar-list-AR
      v-if="lang === 'ar'"
      dir="rtl"
      class="d-none ml-5 flex-grow-1 order-sm-2"
      :list="list"
    ></the-navbar-list-AR>
    <the-navbar-list
      v-else
      class="d-none ml-5 flex-grow-1 order-sm-2"
      :list="list"
    ></the-navbar-list>
    <the-language-selector
      class="flex-shrink-1 d-none d-xl-flex order-10"
    ></the-language-selector>
    <!-- For mobile -->
    <div class="order-2 d-flex">
      <the-language-selector
        class="flex-shrink-1 d-xl-none order-2"
      ></the-language-selector>
      <button
        v-if="list.length > 0"
        class="mobile-btn d-xl-none header-button order-3"
        type="button"
        data-toggle="collapse"
        data-target="#SupportContent"
        aria-controls="SupportContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>
    <div class="collapse navbar-collapse order-10" id="SupportContent">
      <ul class="mobile-list navbar-nav" data-toggle="collapse" data-target="#SupportContent">
        <router-link v-for="item in list" :key="item.id" :to="item.link">
          <li class="navbar-item">{{ item.title }}</li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import TheLanguageSelector from '@/components/TheLanguageSelector/TheLanguageSelector.vue';
import TheNavbarList from '@/components/TheNavbarList/TheNavbarList.vue';
import TheNavbarListAR from '@/components/TheNavbarListAR/TheNavbarListAR.vue';
export default {
  components: {
    TheLanguageSelector,
    TheNavbarList,
    TheNavbarListAR,
  },
  computed: {
    list() {
      let temp = this.$store.getters['navbar/getList'];
      return this.$store.getters['navbar/filterList'](temp);
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    }
  },
};
</script>

<style src="./TheNavbar.css" scoped></style>
