<template>
<div id="footer" class="animate__animated animate__slow  animate__fadeInUp">
    AcquaPalace. All rights reserved © 2021
</div>
</template>

<script>
export default {

}
</script>

<style src="./TheFooter.css" scoped>

</style>
