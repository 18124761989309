<template>
<ul class="nav-list-ar d-xl-flex justify-content-center align-content-center mb-0">
    <li class="nav-list-ar-it" v-for="item in list" :key="item.id">
        <navbar-dropdown :title="item.title" :link="item.link" :hasChild="item.list.length > 0" :list="item.list"></navbar-dropdown>
    </li>
</ul>
</template>

<script>
import navbarDropdown from '@/components/NavbarDropdown/NavbarDropdown.vue'
export default {
    components: {
        navbarDropdown
    },
    props: {
        list: {
            type: Array,
            required: true
        }
    },
}
</script>

<style src="./TheNavbarListAR.css" scoped></style>
