<template>
  <div v-if="hasChild" class="navbar-dropdown dropdown">
    <button
      class="navbar-dropdown-button"
      id="dropdownMenuButton"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <router-link :to="link">
        <span class="mr-1">
          {{ title }}
        </span>
      </router-link>
      <i class="fas fa-chevron-down"></i>
    </button>
    <ul
      class="dropdown-menu navbar-dropdown-list"
      aria-labelledby="dropdownMenuButton"
    >
      <li
        class="navbar-dropdown-list-item"
        v-for="item in filtredList"
        :key="item.id"
      >
        <router-link :to="item.link">
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </div>
  <div class="navbar-dropdown" v-else>
    <router-link :to="link">
      <span class="mr-1">
        {{ title }}
      </span>
    </router-link>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    link: String,
    hasChild: Boolean,
  },
  computed: {
    filtredList() {
      return this.$store.getters['navbar/filterList'](this.list);
    },
  },
  methods: {
    onhover() {
      $('div.dropdown').hover(
        function() {
          $(this)
            .find('.dropdown-menu')
            .stop(true, true)
            .delay(200)
            .fadeIn(400);
        },
        function() {
          $(this)
            .find('.dropdown-menu')
            .stop(true, true)
            .delay(200)
            .fadeOut(500);
        }
      );
    },
  },
  mounted() {
    this.onhover();
  },
};
</script>

<style src="./NavbarDropdown.css" scoped></style>
