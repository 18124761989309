<template>
<div class="map-container d-flex flex-column ">
    <iframe class="map" frameborder="0" style="border:0" :src="map" allowfullscreen>
    </iframe>
    <div @mouseenter="flip()" @mouseleave="flip()" class="google-map p-2 my-2 mx-auto">
        <a href="https://www.google.fr/maps/place/Acqua+Palace/@35.8883716,10.5909863,17z/data=!3m1!4b1!4m5!3m4!1s0x12fd898daac6a361:0x4723408b45c7c09a!8m2!3d35.8883716!4d10.593175">
            Google Maps<img :class="{'animate__flip' : isFlip, 'animate__animated' : isFlip }" src="@/assets/map.webp" alt="google maps link">
        </a>
    </div>
</div>
</template>

<script>
export default {
    mounted() {},
    data() {
        return {
            isFlip: false,
            map: this.$store.getters.getMapSource
        }
    },
    methods: {
        flip() {
            this.isFlip = !this.isFlip;
        }
    }

}
</script>

<style src="./VitrineMap.css" scoped>

</style>
