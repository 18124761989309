<template>
  <div class="vitrine-prix">
    <h2>{{ pricesText.text }}</h2>
    <div
      class="d-flex flex-sm-row flex-column justify-content-center align-items-center"
    >
      <BCard
        v-for="price in this.prices"
        v-bind:key="price.id"
        :imgUrl="price.imgUrl"
        :heights="price.heights"
        :icos="price.icos"
      >
        <template v-slot:Bcard-ico>
          <div v-html="price.ico"></div>
        </template>
        <template v-slot:Bcard-body-title>
          {{ price.name }}
        </template>
        <template v-slot:Bcard-body-description>
          {{ price.desc }}
        </template>
        <template v-slot:Bcard-footer-text>
          <p v-if="isNumber(price.price)">
            <span class="Bcard-footer-text-price">{{ price.price }}</span>
            <sub class="Bcard-footer-text-sub">{{ pricesText.currency }}</sub>
          </p>
          <p v-else>
            <span class="Bcard-footer-text-price-t">{{ price.price }}</span>
          </p>
        </template>
      </BCard>
    </div>
  </div>
</template>

<script>
import BCard from '@/components/BasicCard/BasicCard.vue';
export default {
  components: {
    BCard,
  },
  computed: {
    prices() {
      return this.$store.getters['welcome/getPrices'];
    },
    pricesText() {
      return this.$store.getters['welcome/getPricesText'];
    },
  },
  methods: {
    isNumber(value) {
      return typeof value === 'number';
    }
  },
};
</script>

<style src="./VitrinePrix.css"></style>
