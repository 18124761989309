<template>
  <div
    class="Bcard animate__animated animate__fadeInUp d-flex flex-column justify-content-center align-content-center m-2 p-5"
    :style="{ background: `center/cover url(${imgUrl})` }"
  >
    <div class="Bcard-body p-2 flex-grow-1">
      <div class="Bcard-ico">
        <ruler :heights="heights" :icos="icos"></ruler>
      </div>
      <p class="Bcard-body-title">
        <slot name="Bcard-body-title"></slot>
      </p>
      <p class="Bcard-body-description">
        <slot name="Bcard-body-description"></slot>
      </p>
    </div>
    <div class="Bcard-footer-text">
      <slot name="Bcard-footer-text"></slot>
    </div>
  </div>
</template>

<script>
import Ruler from "@/components/Ruler/Ruler.vue";
export default {
  components: { Ruler },
  props: {
    imgUrl: String,
    icos: String,
    heights: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style src="./BasicCard.css" scoped></style>
