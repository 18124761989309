<template>
  <div class="rulerContainer">
    <div class="ruler">
      <div v-if="heights.max === ''" class="ruler-cell">
        <span class="arrow-up"></span>
        <span class="number">{{ heights.min }}</span>
      </div>
      <div v-else-if="heights.min === ''" class="ruler-cell">
        <span class="arrow-d"></span>
        <span class="zero">{{ heights.max }}</span>
      </div>
      <div v-else class="ruler-cell">
        <span class="zero">{{ heights.max }}</span>
        <span class="number">{{ heights.min }}</span>
      </div>
      <div class="" v-html="icos"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icos: String,
    heights: {
      min: String,
      max: String,
    },
  },
};
</script>

<style src="./Ruler.css" scoped></style>
