<template>
<div class="social-bar d-sm-flex flex-column position-fixed animate__animated animate__delay-3s animate__fadeIn">
    <div class="social-bar-item">
        <a href="https://www.facebook.com/acquapalace.kantaoui/" target="_blank"><img src="@/assets/facebook.webp" alt="Lien vers Facebook"></a>
    </div>
    <div class="social-bar-item">
        <a href="https://www.instagram.com/acquapalacekantaoui/?igshid=1a5i3077qmmqv" target="_blank"><img src="@/assets/instagram.webp" alt="lien vers Instagram"></a>
    </div>
    <div class="social-bar-item">
        <a href="https://www.youtube.com/user/AcquaPalace/" target="_blank"><img src="@/assets/youtube.webp" alt="Lien vers Youtube"></a>
    </div>
    <div class="social-bar-item">
        <a href="https://www.tripadvisor.com/Attraction_Review-g297952-d3386110-Reviews-Aqua_Palace-Port_El_Kantaoui_Sousse_Governorate.html" target="_blank"><img src="@/assets/tripadvlogo.webp" alt="Lien vers tripadvisor"></a>
    </div>
    <div class="social-bar-item-t">
        <a href="https://twitter.com/AcquaPalace" target="_blank"><img src="@/assets/x.jpeg" alt="Lien vers X"></a>
    </div>
    <div class="social-bar-item-t mt-md-2">
        <a href="https://www.waterside.acquapalace.com" target="_blank"><img src="@/assets/logo_v1_whitebg-2.jpeg" alt="Lien vers Waterside"></a>
    </div>
</div>
</template>

<script>
export default {}
</script>

<style src="./SocialBar.css" scoped>

</style>
