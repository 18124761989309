<template>
  <div
    class="vitrine-nav p-3 mb-4 d-none d-md-flex align-items-center animate__fadeInUp animate__slower animate__animated"
  >
    <!-- Init  -->
    <router-link :to="{ name: 'home' }">
      <i
        class="fas fa-info-circle vitrine-nav-item vitrine-nav-welcome"
        :class="{ 'vitrine-nav-item-active': $route.name === 'home' }"
      ></i>
    </router-link>
    <!-- Horaire -->
    <router-link :to="{ name: 'horaire' }">
      <i
        class="far fa-calendar-alt vitrine-nav-item vitrine-nav-clock"
        :class="{ 'vitrine-nav-item-active': $route.name === 'horaire' }"
      ></i>
    </router-link>
    <!-- prix -->
    <router-link :to="{ name: 'prix' }">
      <i
        class="fas fa-money-bill-wave vitrine-nav-item vitrine-nav-price"
        :class="{ 'vitrine-nav-item-active': $route.name === 'prix' }"
      ></i>
    </router-link>
    <!-- NoFood -->
    <router-link :to="{ name: 'NoFood' }">
      <i
        class="fas fa-solid fa-ban vitrine-nav-item vitrine-nav-nofood"
        :class="{ 'vitrine-nav-item-active': $route.name === 'NoFood' }"
      ></i>
    </router-link>
    <!-- map -->
    <router-link :to="{ name: 'map' }">
      <i
        class="fas fa-map-signs vitrine-nav-item vitrine-nav-map"
        :class="{ 'vitrine-nav-item-active': $route.name === 'map' }"
      ></i>
    </router-link>
        <!-- Media  -->
    <router-link :to="{ name: 'media' }">
      <i
        class="fas fa-photo-video vitrine-nav-item vitrine-nav-media"
        :class="{ 'vitrine-nav-item-active': $route.name === 'media' }"
      ></i>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped src="./VitrineNav.css"></style>
