<template>
 <div
    class="p-4 d-flex flex-column flex-sm-row justify-content-center align-content-center"
  >
    <div class="cf m-3 text-center">
        <strong class="cf-text"> {{ info[lang] }} </strong>
        <div class="justify-content-center align-content-center">
            <img
                class="cf-img my-1 p-5"
                src="@/assets/rules/no-food-2.jpeg"/>
        </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
    return {
        info: {
            fr: 'Il est strictement interdit d’introduire dans le parc boissons et aliments. Uniquement les aliments pour bébés sont autorisés.',
            en: 'It is strictly forbidden to bring drinks and food in the park. Only baby food is allowed.',
            ar: 'منع منعا باتا إدخال المشروبات و المواد الغذائية إلى المنتزه و يسمح فقط بطعام الرضع.',
        },
    }
  },
  components: {
  },
  computed: {
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
};
</script>

<style src="./VitrineRules.css"></style>